import React, { useState } from "react";
import { Link } from "react-router-dom";

const WeekendModeContent = () => {
  const [isWeekendMode, setIsWeekendMode] = useState(false);

  const handleWeekendModeClick = () => {
    setIsWeekendMode((prevMode) => !prevMode);

    if (!isWeekendMode) {
      // If weekend mode is enabled
      // Add hidepage className and remove showpage className from various elements
      const elementsToHide = document.querySelectorAll(
        ".salva-project-detail, .salva-portfolio-content, .salva-image-page, .salva-soundcloud-page, .salva-vimeo-content, .salva-youtube-video-page, .salva-home-main-content, .weekend-mode-hide, .salva-slider-page"
      );
      elementsToHide.forEach((element) => {
        element.classNameList.add("hidepage");
        element.classNameList.remove("showpage");
      });
    } else {
      // If weekend mode is disabled
      // Remove showpage className from .salva-project-detail and .salva-single-news-content
      const elementsToShow = document.querySelectorAll(
        ".salva-project-detail, .salva-single-news-content"
      );
      elementsToShow.forEach((element) =>
        element.classNameList.remove("showpage")
      );

      // Remove hidepage className from .salva-portfolio-content, .salva-home-main-content, and .weekend-mode-hide
      const elementsToUnhide = document.querySelectorAll(
        ".salva-portfolio-content, .salva-home-main-content, .weekend-mode-hide"
      );
      elementsToUnhide.forEach((element) =>
        element.classNameList.remove("hidepage")
      );
    }

    // Toggle classNamees for .salva-weekend-mode, .salva-home-image, and .salva-weekend-image
    document
      .querySelector(".salva-weekend-mode")
      .classNameList.toggle("showpage");
    document
      .querySelector(".salva-home-image")
      .classNameList.toggle("showpage");
    document
      .querySelector(".salva-weekend-image")
      .classNameList.toggle("visible-img");
  };

  return (
    <>
      <div
        className={`salva-weekend-mode ${isWeekendMode ? "checked" : ""}`}
        id="weekend_mode"
      >
        <div className="salv-inrto-content">
          <h5 className="salv-about-title salv-home-title md-font w-text inter-font-900 mb-0">
            Me on Weekends 😎
          </h5>
        </div>
        <div className="salv-week-desc">
        <p className="mt-0 mb-0 sal-week-desc-one inter-font-400 sm-md-font-two">
            When I'm not optimizing cloud infrastructure, you can find me 
            exploring the lush trails of Portland. I cherish the peaceful 
            moments of hiking through Forest Park, where the sounds of the city 
            fade away. At home, I unwind by brewing my own craft beer and 
            indulging in a good book. Life is a balance of nature and technology.
          </p>
          <p className="mb-0 mt-0 sal-week-desc-two inter-font-400 sm-md-font-two">
            I find joy in the small things, like a quiet afternoon spent 
            kayaking on the Willamette River or tending to my vegetable garden. 
            These hobbies not only relax me but also inspire my work as a 
            DevOps engineer. They remind me to stay grounded and to keep 
            learning and growing, both personally and professionally.
          </p>
          <p className="mt-0 mb-0 sal-week-desc-three inter-font-400 sm-md-font-two">
            Whether I'm cycling through the vibrant streets of Portland or 
            experimenting with new recipes in the kitchen, I embrace a lifestyle 
            that blends the outdoors with cozy indoor activities. This harmony 
            fuels my creativity and problem-solving skills, making me a better 
            engineer and a happier individual.
          </p>
        </div>
        <div className="salv-drop-mail">
          <div className="salv-drop-mail-wrap">
            <h6 className="mb-0 drop-mail-title inter-font-400 w-text">
              Drop Your Mail:
            </h6>
            <Link
              style={{ color: "white" }}
              to="mailto:rainmanjam@gmail.com"
              className="salv-mail-text inter-font-900 w-text"
            >
              &nbsp;rainmanjam@gmail.com
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default WeekendModeContent;
